import React from 'react';
import styled from 'styled-components';
import colors from './theme';
import Img from 'gatsby-image';

const GalleryGrid = (props) => {

    const marginValue = '.125vw'

    const GalleryGridSection = styled.section`
    display: flex;
    flex-direction: column; 
    background-color: #FFF;
   
    `

    const GalleryGridRow = styled.div`
    
    display: flex;
    box-sizing: border-box;
    margin: ${marginValue} 0px ${marginValue} 0px;
    height: 24vw;
    align-items: center;
    `

    const GalleryGridImageSmall = styled.div`
    height: 100%;
   
    border-radius: 5px;
    margin: ${marginValue};
    cursor: pointer;
    box-sizing: border-box;
    overflow: hidden;
    width: 20vw;
    background-color: ${colors.blue};
    `

    const GalleryGridImageMedium = styled.div`
    height: 100%;
    margin: ${marginValue};
    border-radius: 5px;
    cursor: pointer;
    box-sizing: border-box;
    overflow: hidden;
    width: 40vw;
    background-color: ${colors.gray};
    `

    const GalleryGridImageLarge = styled.div`
    height: 100%;
    margin: ${marginValue};
    border-radius: 5px;
    cursor: pointer;
    box-sizing: border-box;
    overflow: hidden;
    width: 60vw;
    background-color: ${colors.tan}
    `

    const { photos, setPhotoIndex, setIsLightBoxOpen, } = props

    const onClickHandler = (index) => {
        setPhotoIndex(index);
        setIsLightBoxOpen(true);
        
    }



    return (
        <GalleryGridSection>
            <GalleryGridRow>
                <GalleryGridImageLarge onClick={()=>onClickHandler(0)}>
                    <Img 
                    fluid={photos && photos[0].fluid}
                    alt="Property Image 1"
                    fadeIn="true"
                    loading="lazy"  />
                </GalleryGridImageLarge>
                <GalleryGridImageMedium onClick={()=>onClickHandler(1)} >
                    <Img 
                    fluid={photos && photos[1].fluid}
                    alt="Property Image 2"
                    fadeIn="true"
                    loading="lazy" />
                </GalleryGridImageMedium>
            </GalleryGridRow>
            <GalleryGridRow>
                <GalleryGridImageSmall onClick={()=>onClickHandler(2)} >
                    <Img 
                    fluid={photos && photos[2].fluid}
                    alt="Property Image 3"
                    fadeIn="true"
                    loading="lazy" />
                </GalleryGridImageSmall>
                <GalleryGridImageLarge onClick={()=>onClickHandler(3)}>
                    <Img 
                    fluid={photos && photos[3].fluid}
                    alt="Property Image 4"
                    fadeIn="true"
                    loading="lazy" />
                </GalleryGridImageLarge>
                <GalleryGridImageSmall onClick={()=>onClickHandler(4)}>
                    <Img 
                    fluid={photos && photos[4].fluid}
                    alt="Property Image 5"
                    fadeIn="true"
                    loading="lazy" /> 
                </GalleryGridImageSmall>   
            </GalleryGridRow>
            {photos.length > 7 && <><GalleryGridRow>
                <GalleryGridImageMedium onClick={()=>onClickHandler(5)}>
                    <Img 
                    fluid={photos && photos[5].fluid}
                    alt="Property Image 6"
                    fadeIn="true"
                    loading="lazy" />
                </GalleryGridImageMedium>
                <GalleryGridImageLarge onClick={()=>onClickHandler(6)}>
                    <Img 
                    fluid={photos && photos[6].fluid}
                    alt="Property Image 6"
                    fadeIn="true"
                    loading="lazy" />
                </GalleryGridImageLarge>
            </GalleryGridRow>
            
            <GalleryGridRow>
            <GalleryGridImageLarge onClick={()=>onClickHandler(7)}>
                    <Img 
                    fluid={photos && photos[7].fluid}
                    alt="Property Image 7"
                    fadeIn="true"
                    loading="lazy" />
                </GalleryGridImageLarge>
                <GalleryGridImageLarge onClick={()=>onClickHandler(8)}>
                    <Img 
                    fluid={photos && photos[8].fluid}
                    alt="Property Image 8"
                    fadeIn="true"
                    loading="lazy" />
                </GalleryGridImageLarge>
            </GalleryGridRow></>
            }
        </GalleryGridSection>
    )
}

export default GalleryGrid;