import React, { useState } from 'react';
import ReactMapGL, { Popup, Marker } from 'react-map-gl';
import MapMarker from './mapmarker';

const Map = (props) => {

const mapStyle = "mapbox://styles/apchoquette/ck3drqaqo24l31cnz1mpy10in"

  const [viewport, setViewport] = useState({
    width: "100%",
    height: "100%",
    latitude: 37.8181,
    longitude: -122.274888,
    zoom: 13.24
  });

  const [poppedUp, setPoppedUp] = useState(false);

 

 


  return (
    <ReactMapGL
      {...viewport}
      mapStyle={mapStyle}
      onViewportChange={setViewport}
      mapboxApiAccessToken={process.env.MAPBOX_TOKEN || "pk.eyJ1IjoiYXBjaG9xdWV0dGUiLCJhIjoiY2szZHFlaXp1MWQxZjNlbWhzZWw5bTIwMiJ9.ogBfp50GUvNrYCqyQeigeA"}
    >
        {props.children}
    </ReactMapGL>
  );
}

export default Map;