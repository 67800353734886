import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
 

const LightboxGallery = (props) => {

    const { images, photoIndex, isOpen, setPhotoIndex, setIsOpen } = props

    return (
      <div>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].fluid.src}
            nextSrc={images[(photoIndex + 1) % images.length].fluid.src}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              
                setPhotoIndex((photoIndex + 1) % images.length)
              }
            
          />
        )}
      </div>
    )
}

export default LightboxGallery;
